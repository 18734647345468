import React from 'react';
import './styles/CheckboxOutline.scss';

const CheckboxOutline = ({ text }) => {
    return (
        <div className="CheckboxOutline">
            <label className="container">
                <span>{text}</span>
                <input type="checkbox" />
                <span className="checkmark"></span>
            </label>
        </div>
    );
};

export default CheckboxOutline;
