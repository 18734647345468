import React from 'react';
import './styles/Checkbox.scss';

const Checkbox = ({ text, checked, onChange }) => {
    return (
        <div className="Checkbox">
            <label className="container">
                <span>{text}</span>
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                />
                <span className="checkmark"></span>
            </label>
        </div>
    );
};

export default Checkbox;
