import React from 'react';
import { motion } from 'framer-motion';
import './styles/LightWrapper.scss';

const LightWrapper = ({ children, padding }) => {
    return (
        <motion.section
            initial={{ y: '20', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: "easeOut", duration: 0.5 }}
            className='LightWrapper' style={{ padding: `${padding ? '24px' : ''}` }}>
            {children}
        </motion.section>
    );
};

export default LightWrapper;
