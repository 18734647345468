import React from 'react';
import './styles/skeleton.scss';

const ProfileCardSkeleton = () => {
    return (
        <div className="col-12 col-md-6 col-lg-3">
            <div className='Wrapper'>
                <div className="ProfileCardSkeleton">
                    <div className="photo">
                    </div>
                    <div className="user"></div>
                    <div className="email"></div>
                    <div className="__btn">
                    </div>
                </div>
                <div className="shimmer-wrapper">
                    <div className="shimmer"></div>
                </div>
            </div>
        </div>
    );
};

export default ProfileCardSkeleton;
