import { convertFromRaw, EditorState } from "draft-js";
import { convertToRaw } from "draft-js";
import Cookies from "js-cookie";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useUpdateEffect } from "react-use";
import { useGetCategoriesQuery, useGetNewsByIdQuery, useUpdateNewsMutation } from "../../../api/news";
import DashboardLayout from "../../../components/layoutComponents/DashboardLayout";
import LightWrapper from "../../../components/sharedComponents/LightWrapper";
import flag from '../../../images/flag.png';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthor, setCategory, setEditorState, setImageUrl, setPublish, setStorylink, setTitle } from "../../../app/features/editNewsSlice";
import listenForOutsideClicks from "../../../utilities/listen-for-outside-clicks";


const Edit = () => {
    const [file, setFile] = useState(null);
    // const [author, setAuthor] = useState('');
    // const [title, setTitle] = useState('');
    // const [storylink, setStorylink] = useState('');
    // const [category, setCategory] = useState('General');
    const [showDropdown, setShowDropdown] = useState(false);
    // const [publish, setPublish] = useState(false);
    const [showPublishDropdown, setShowPublishDropdown] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const { id } = useParams();


    const menuRef = useRef(null);
    const [listening, setListening] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(listenForOutsideClicks(listening, setListening, menuRef, setShowPublishDropdown));

    const dispatch = useDispatch();
    const author = useSelector((state) => state.editNewsData.author);
    const title = useSelector((state) => state.editNewsData.title);
    const storylink = useSelector((state) => state.editNewsData.storylink);
    const category = useSelector((state) => state.editNewsData.category);
    const publish = useSelector((state) => state.editNewsData.publish);
    const imageUrl = useSelector((state) => state.editNewsData.imageUrl);
    const editorState = useSelector((state) => state.editNewsData.editorState);

    const navigate = useNavigate();

    const token = Cookies.get('CCMAdminToken');

    // const [editorState, setEditorState] = useState(
    //     () => EditorState.createEmpty(),
    // );

    const { error: errorPost, isError: isErrorPost, data: dataPost, isSuccess: isSuccessPost } = useGetNewsByIdQuery({ token, id });

    useUpdateEffect(() => {
        try {
            if (isErrorPost) toast.error(errorPost.data.responseDescription, { autoClose: 3000 });
        } catch (error) {
            if (!window.navigator.online) toast.error('Your internet connection is off');
        }
    }, [isErrorPost]);

    useUpdateEffect(() => {
        dispatch(setAuthor(dataPost?.data?.author));
        dispatch(setTitle(dataPost?.data?.title));
        dispatch(setStorylink(dataPost?.data?.storylink || ''));
        dispatch(setCategory(dataPost?.data?.category));
        dispatch(setPublish(dataPost?.data?.status));
        dispatch(setImageUrl(dataPost?.data?.imageUrl));

        const markdown = dataPost?.data.body;
        const rawData = markdownToDraft(markdown);
        const contentState = convertFromRaw(rawData);
        const editorState = EditorState.createWithContent(contentState);
        dispatch(setEditorState(editorState));
    }, [isSuccessPost]);

    // useEffect(() => {
    //     const markdown = '#### Hey this **editor** rocks 😀';
    //     const rawData = markdownToDraft(markdown);
    //     const contentState = convertFromRaw(rawData);
    //     const editorState = EditorState.createWithContent(contentState);
    //     setEditorState(editorState);
    // }, []);

    const [updateNews, updateNewsResult] = useUpdateNewsMutation();

    const { isLoading: isLoadingCategories, error: errorCategories, isError: isErrorCategories, data: dataCategories } = useGetCategoriesQuery(token);

    const { isLoading, error, isError, data, isSuccess } = updateNewsResult;

    isSuccess && console.log(data);

    useUpdateEffect(() => {
        try {
            if (isError) toast.error(error.data.responseDescription, { autoClose: 3000 });
        } catch (error) {
            if (!window.navigator.online) toast.error('Your internet connection is off');
        }
    }, [isError]);

    useUpdateEffect(() => {
        try {
            if (isSuccess) toast.success(data.responseDescription, { autoClose: 3000 });
        } catch (error) {
            if (!window.navigator.online) toast.error('Your internet connection is off');
        }
    }, [isSuccess]);


    useUpdateEffect(() => {
        try {
            if (isErrorCategories) toast.error(errorCategories.data.responseDescription, { autoClose: 3000 });
        } catch (error) {
            if (!window.navigator.online) toast.error('Your internet connection is off');
        }
    }, [isErrorCategories]);


    const types = ['image/png', 'image/jpeg'];

    const onFileChange = (e) => {
        let selected = e.target.files[0];
        if (selected && types.includes(selected.type)) {
            const reader = new FileReader();
            reader.onloadend = () => {
                dispatch(setImageUrl(reader.result));
            };
            reader.readAsDataURL(selected);
            dispatch(setFile(selected));
        } else {
            dispatch(setFile(null));
            toast.error('Please select an image file (png or jpg)');
        }
    };

    useUpdateEffect(() => {
        if (author === '' || title === '' || `${draftToMarkdown(convertToRaw(editorState.getCurrentContent()))}` === '') {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [author, title, editorState]);

    const Create = () => {
        let formData = new FormData();

        if (file) formData.append('file', file);
        formData.append('author', author);
        formData.append('id', id);
        formData.append('authorDescription', author);
        formData.append('category', category);
        formData.append('title', title);
        formData.append('storylink', storylink);
        formData.append('status', publish);
        formData.append('body', draftToMarkdown(convertToRaw(editorState.getCurrentContent())));

        updateNews({ token, formData });
    };

    return (
        <DashboardLayout>
            <div className="CreatePost">
                <LightWrapper>
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <div className="container">
                                <div className="division">
                                    <div className="header mt-4 px-4">
                                        <div className="lan">
                                            <div className="flag">
                                                <img src={flag} alt="" />
                                            </div>
                                            English
                                        </div>
                                        <div ref={menuRef} className="status" onClick={() => setShowPublishDropdown(!showPublishDropdown)}>
                                            <div className="d-flex align-items-center">
                                                <div className="dot" style={{ backgroundColor: `${publish ? '#38cb89' : '#cbcb38'}` }}></div>
                                                {publish ? 'Published' : 'Draft'}
                                            </div>
                                            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.45129 4.93261C1.10497 5.25855 0.56 5.24204 0.234055 4.89572C-0.0918898 4.54941 -0.0753754 4.00443 0.270941 3.67849L3.93066 0.234043C4.26223 -0.0780239 4.77944 -0.0780239 5.11101 0.234043L8.77073 3.67849C9.11705 4.00443 9.13357 4.54941 8.80762 4.89572C8.48168 5.24204 7.9367 5.25855 7.59038 4.93261L4.52084 2.04362L1.45129 4.93261ZM1.45129 10.5674L4.52084 13.4564L7.59038 10.5674C7.9367 10.2414 8.48167 10.258 8.80762 10.6043C9.13356 10.9506 9.11705 11.4956 8.77073 11.8215L5.11101 15.2659C4.77944 15.578 4.26223 15.578 3.93066 15.2659L0.270938 11.8215C-0.0753779 11.4956 -0.0918922 10.9506 0.234052 10.6043C0.559997 10.258 1.10497 10.2414 1.45129 10.5674Z" fill="#B0B7C3" />
                                            </svg>
                                            {
                                                showPublishDropdown &&
                                                <div className="dropdown">
                                                    <div onClick={() => dispatch(setPublish(false))}>
                                                        <div className="color" style={{ backgroundColor: '#38cb89' }}></div>
                                                        <div className="text">Draft</div>
                                                    </div>
                                                    <div onClick={() => dispatch(setPublish(true))}>
                                                        <div className="color" style={{ backgroundColor: '#cbcb38' }}></div>
                                                        <div className="text">Published</div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="hr"></div>
                                    <div className="editor mt-5 px-4">
                                        <div className="title_img">
                                            <div className="photo"></div>
                                            <div className="title">
                                                <input type="text" value={title} onChange={(e) => dispatch(setTitle(e.target.value))} placeholder="Title Here..." />
                                            </div>
                                        </div>
                                        <div>
                                            <Editor
                                                wrapperClassName="wrapper-class"
                                                editorClassName="editor-class"
                                                toolbarClassName="toolbar-class"
                                                editorState={editorState}
                                                onEditorStateChange={editorState => dispatch(setEditorState(editorState))}
                                                placeholder={'Type here...'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="division">
                                <div className="section">
                                    <h3>Category</h3>
                                    <span>This article will appear in:</span>

                                    <div className="select" onClick={() => setShowDropdown(showDropdown)}>
                                        {isLoadingCategories ? 'Loading Categories...' : category}
                                        {/* <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 1L5.25 5.25L9.5 1" stroke="#B0B7C3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg> */}
                                        {
                                            showDropdown &&
                                            <div className="dropdown">
                                                {
                                                    (!isLoadingCategories && dataCategories?.data) &&
                                                    [...dataCategories?.data].sort((a, b) => (a.name.toLowerCase().localeCompare(b.name.toLowerCase()))).map(cat => (
                                                        <div onClick={() => dispatch(setCategory(cat.name))} key={cat.id}>
                                                            <div className="text">{cat.name}</div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="section">
                                    <h3>Author</h3>
                                    <div className="select">
                                        <input type="text" value={author} onChange={e => dispatch(setAuthor(e.target.value))} placeholder="Author's name" />
                                    </div>
                                </div>
                                <div className="section">
                                    <h3>Story Link</h3>
                                    <div className="select">
                                        <input type="text" value={storylink} onChange={e => dispatch(setStorylink(e.target.value))} placeholder="Story link" />
                                    </div>
                                </div>
                                <div className="section">
                                    <h3>Featured Image</h3>
                                    {
                                        imageUrl &&
                                        <div className="uploaded_Image">
                                            <img src={imageUrl} alt="" />
                                        </div>
                                    }
                                    <label>
                                        <input type='file' onChange={onFileChange} />
                                        <div>
                                            <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="26" cy="26" r="26" fill="#F3F3F3" />
                                                <path opacity="0.3" d="M15.6113 27.0387C15.6113 26.5193 16.1307 25.9999 16.6501 25.9999C17.1695 25.9999 17.689 26.5193 17.689 27.0387C17.689 27.385 17.689 29.1163 17.689 32.2328C17.689 33.3802 18.6191 34.3104 19.7666 34.3104H32.2323C33.3798 34.3104 34.31 33.3802 34.31 32.2328V27.0387C34.31 26.465 34.7751 25.9999 35.3488 25.9999C35.9225 25.9999 36.3876 26.465 36.3876 27.0387V32.2328C36.3876 34.5276 34.5272 36.388 32.2323 36.388H19.7666C17.4717 36.388 15.6113 34.5276 15.6113 32.2328C15.6113 29.1163 15.6113 27.385 15.6113 27.0387Z" fill="#8A94A6" />
                                                <path opacity="0.3" d="M27.0386 16.6506C27.0386 16.0769 26.5735 15.6118 25.9997 15.6118C25.426 15.6118 24.9609 16.0769 24.9609 16.6506V29.1164C24.9609 29.6901 25.426 30.1552 25.9997 30.1552C26.5735 30.1552 27.0386 29.6901 27.0386 29.1164V16.6506Z" fill="#8A94A6" />
                                                <path d="M26.0371 17.0433L21.54 21.5404C21.1343 21.9461 20.4765 21.9461 20.0709 21.5404C19.6652 21.1347 19.6652 20.477 20.0709 20.0713L25.2649 14.8773C25.6556 14.4866 26.2837 14.4701 26.6944 14.8397L31.8885 19.5143C32.3149 19.8981 32.3495 20.555 31.9657 20.9814C31.5819 21.4078 30.9251 21.4424 30.4986 21.0586L26.0371 17.0433Z" fill="#8A94A6" />
                                            </svg>
                                            Drag and Drop or Browse ,<br /> to upload
                                        </div>
                                    </label>
                                    <div className="buttons">
                                        <button className="cancel" onClick={() => navigate(-1)}>Cancel</button>
                                        <button className={`save ${disabled ? 'disabled' : ''}`} disabled={disabled} onClick={Create}>{isLoading ? <ThreeDots type="ThreeDots" color="#FFF" height={16} width={80} /> : 'Save'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </LightWrapper>
            </div>
        </DashboardLayout>
    );
};

export default Edit;
