import React, { useEffect, useRef, useState } from 'react';
import listenForOutsideClicks from "../../utilities/listen-for-outside-clicks";
import './styles/SelectInput.scss';

const SelectInput = ({ option, options, getValue }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [customSelectValue, setCustomSelectValue] = useState('');

    const menuRef = useRef(null);
    const [listening, setListening] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(listenForOutsideClicks(listening, setListening, menuRef, setShowDropdown));

    useEffect(() => {
        getValue(customSelectValue);
    }, [customSelectValue, getValue]);


    return (
        <div ref={menuRef} className="SelectInput" onClick={() => setShowDropdown(!showDropdown)}>
            <div className="select">
                <span className='option'>{customSelectValue || option}</span>
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.0002 1.33346C12.0002 1.50413 11.9348 1.67479 11.8048 1.80479L6.4715 7.13813C6.21083 7.39879 5.7895 7.39879 5.52883 7.13813L0.1955 1.80479C-0.0651667 1.54413 -0.0651667 1.12279 0.1955 0.862126C0.456167 0.601459 0.8775 0.601459 1.13817 0.862126L6.00017 5.72413L10.8622 0.862126C11.1228 0.601459 11.5442 0.601459 11.8048 0.862126C11.9348 0.992126 12.0002 1.16279 12.0002 1.33346Z" fill="#4D4476" />
                </svg>
            </div>
            {
                showDropdown &&
                <div className="dropdown">
                    <div onClick={() => setCustomSelectValue('')}>
                        <div className="text">All</div>
                    </div>
                    {
                        options && options.map((option, i) => (
                            <div onClick={() => setCustomSelectValue(option)} key={i}>
                                <div className="text">{option}</div>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    );
};


export default SelectInput;
