import React from 'react';
import './styles/Input.scss';

const Input = ({ label, placeholder, type, id, onChange, value, max, autoComplete, disabled }) => {
    return (
        <div className="Input">
            <label htmlFor={id}>{label}</label>
            <input
                type={type}
                placeholder={placeholder}
                id={id}
                value={value}
                onChange={onChange}
                maxLength={max}
                autoComplete={autoComplete || 'off'}
                disabled={disabled || false}
            />
        </div>
    );
};

export default Input;
