import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


const createRequest = (url, token) => ({
    url, headers: {
        'Authorization': `Bearer ${token}`,
    }
});



export const donations = createApi({
    reducerPath: 'donations',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
    refetchOnReconnect: true,
    endpoints: (build) => ({
        getDonations: build.query({
            query: (data) => createRequest(`/api/v1/admin/donations?pageNumber=${data.pageNumber}&pageSIze=${data.pageSize}&status=all`, data.token),
        }),
    }),
});

export const {
    useGetDonationsQuery
} = donations;