class AuthApi {
    static async login(details) {
        try {
            const result = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/Login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(details)
            });
            const data = await result.json();
            return data;
        } catch (e) {
            console.log(e);
        }
    }
    static async forgotPassword(details) {
        try {
            const result = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/password/forgot`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(details)
            });
            const data = await result.json();
            return data;
        } catch (e) {
            console.log(e);
        }
    }
    static async changePassword({ details, token }) {
        try {
            const result = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/password/change`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(details)
            });
            const data = await result.json();
            return data;
        } catch (e) {
            console.log(e);
        }
    }
}
export default AuthApi;
