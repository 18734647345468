import React from 'react';
import './styles/Button.scss';

const Button = ({ value, disabled, bgColor }) => {
    return (
        <div className="Button">
            <button
                disabled={disabled || false}
                className={`${disabled ? 'disabled' : ''}`}
                style={{ backgroundColor: `${bgColor ? bgColor : '#369afe'}` }}
            >
                {value}
            </button>
        </div>
    );
};

export default Button;
