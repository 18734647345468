import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


const createRequest = (url, token) => ({
    url, headers: {
        'Authorization': `Bearer ${token}`,
    }
});



export const dashboard = createApi({
    reducerPath: 'dashboard',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
    refetchOnReconnect: true,
    endpoints: (build) => ({
        getDashboardChart: build.query({
            query: (data) => createRequest(`/api/v1/admin/dashboard/chart?startDate=${data.startDate}&endDate=${data.endDate}`, data.token),
            keepUnusedDataFor: 0,
        }),
        getDashboardStats: build.query({
            query: (token) => createRequest('/api/v1/admin/dashboard', token),
        }),
        getMostPopularState: build.query({
            query: (token) => createRequest('/api/v1/admin/dashboard/state', token),
        }),
    }),
});

export const {
    useGetDashboardChartQuery,
    useGetDashboardStatsQuery,
    useGetMostPopularStateQuery
} = dashboard;