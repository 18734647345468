import React from 'react';
import LightWrapper from "../sharedComponents/LightWrapper";
import './styles/skeleton.scss';

const NewsCardSkeleton = () => {
    return <div className="NewsCardSkeleton">
        <LightWrapper>
            <div className="division">
                <div className="head">
                    <div>
                        <div className="photo">
                        </div>
                        <div>
                            <div className="author"></div>
                            <div className="time"></div>
                        </div>
                    </div>
                    <div className="tag"></div>
                </div>
                <div>
                    <div className="featured_image">
                    </div>
                </div>
                <div >
                    <div className="title"></div>
                </div>
                <div className="text"></div>
                <div className="text"></div>
            </div>
            <div className="hr"></div>
            <div className="division">

            </div>
        </LightWrapper>
        <div className="shimmer-wrapper">
            <div className="shimmer"></div>
        </div>
    </div>;
};

export default NewsCardSkeleton;
