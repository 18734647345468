import React from 'react';
import LightWrapper from '../sharedComponents/LightWrapper';

const UserSkeleton = () => {
    return (
        <main className='UserSkeleton'>
            <div className='h1'></div>

            <LightWrapper padding={true}>
                <div className='h3'></div>
                <span></span>
                <div className="photo_details">
                    <div className="photo">
                    </div>
                    <div className="details">
                        <div className="name"></div>
                        <div className="email"></div>
                    </div>
                </div>

                <form>
                    <div className='label' />
                    <div className='input' />
                    <div className="row">
                        <div className="col col-md-6">
                            <div className='label' />
                            <div className='input' />
                        </div>
                        <div className="col col-md-6">
                            <div className='label' />
                            <div className='input' />
                        </div>
                    </div>
                    <div className='label' />
                    <div className='input' />
                    <div className='label' />
                    <div className='input' />
                </form>
            </LightWrapper>
            <div className="shimmer-wrapper">
                <div className="shimmer"></div>
            </div>
        </main>
    );
};

export default UserSkeleton;
