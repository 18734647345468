import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import AuthApi from "../../api/AuthApi";
import Button from '../../components/inputs/Button';
import Input from '../../components/inputs/Input';
import { validateEmail } from "../../utilities/validateEmail";
import './styles/ResetPassword.scss';

const ResetPassword = () => {
    const [buttonValue, setButtonValue] = useState('RESET');

    const { control, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        let details = {};
        const { email } = data;
        const isEmail = validateEmail(email);
        if (isEmail) {
            details.username = email;
        } else {
            toast.error('Please use a valid email');
            return;
        }

        setButtonValue(<ThreeDots type="ThreeDots" color="#FFF" height={16} width={80} />);

        try {
            const response = await AuthApi.forgotPassword(details);
            if (response.responseCode === "10401") {
                toast.error(response.responseDescription);
            } else {
                toast.success(response.responseDescription);
            }
        } catch (error) {
        }

        setButtonValue('RESET');
    };

    useEffect(() => {
        if (errors.email) {
            toast.error(errors.email.message);
        }
    }, [errors.email]);
    return (
        <div className='ResetPassword'>
            <main>
                <h1>Reset Password</h1>
                <p>Enter your email address you used to register. We will send you an email containing your username and a link to reset your password.</p>

                <form>
                    <div className='email'>
                        <Controller
                            control={control}
                            rules={{ required: "email is required." }}
                            name="email"
                            render={({
                                field: { onChange },
                            }) => (
                                <Input label='YOUR NAME / EMAIL' onChange={onChange} placeholder='mumuarif@gmail.com' type='email' id='email' />
                            )}
                        />
                    </div>
                    <div className='button' onClick={handleSubmit(onSubmit)}>
                        <Button value={buttonValue} />
                    </div>
                    <span className="need_help">If you still need help, contact <Link to='/resetpassword'>CCM Support</Link></span>
                </form>
            </main>
        </div>
    );
};

export default ResetPassword;
