import React, { useState } from 'react';
import DashboardLayout from '../../components/layoutComponents/DashboardLayout';
import LightWrapper from '../../components/sharedComponents/LightWrapper';
import ProfileCard from '../../components/sharedComponents/ProfileCard';
// import photo from '../../images/Oval.png';
// import photo2 from '../../images/Oval-2.png';
import './styles/Volunteer.scss';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useGetUserByIdQuery, useGetUsersRegByVolunteerQuery } from '../../api/people';
import VolunteerSkeleton from '../../components/skeletons/VolunteerSkeleton';
import { ordinal_suffix_of } from '../../utilities/ordinal_suffix_of';
import { useDispatch, useSelector } from 'react-redux';
import { setPagesCount } from '../../app/features/VolunteerSlice';

const Volunteer = () => {
    const { search: _search } = useLocation();
    const pageNumber = parseInt(new URLSearchParams(_search).get("page"));

    const navigate = useNavigate();

    const [pageSize] = useState(20);
    const { id } = useParams();



    const dispatch = useDispatch();
    const pagesCount = useSelector((state) => state.volunteerData.pagesCount);

    const token = Cookies.get('CCMAdminToken');

    const { isLoading, error, isError, data } = useGetUserByIdQuery({ token, id });
    const { isLoading: isLoading2, error: error2, isError: isError2, data: data2, isSuccess } = useGetUsersRegByVolunteerQuery({ token, id, pageNumber, pageSize }, { refetchOnMountOrArgChange: true });

    useUpdateEffect(() => {
        let pagesCountArray = [];

        for (let i = 0; i < (Math.ceil(data2?.totalCount / pageSize)); i++) {
            pagesCountArray.push(i);

        }
        dispatch(setPagesCount(pagesCountArray));
    }, [isSuccess]);


    useUpdateEffect(() => {
        try {
            if (isError) toast.error(error.data.responseDescription, { autoClose: 3000 });
        } catch (error) {
            if (!window.navigator.online) toast.error('Your internet connection is off');
        }
    }, [isError]);

    useUpdateEffect(() => {
        try {
            if (isError2) toast.error(error2.data.responseDescription, { autoClose: 3000 });
        } catch (error) {
            if (!window.navigator.online) toast.error('Your internet connection is off');
        }
    }, [isError2]);


    return (
        <DashboardLayout>
            <div className="Volunteer">
                {
                    (isLoading || isLoading2 || !data || !data2) &&
                    <VolunteerSkeleton />
                }
                {
                    (data?.user && data && data2?.data) &&
                    <LightWrapper>
                        <div className="gray">
                            <div className="division">
                                <Link to='/volunteers/leaderboard' className="leadership_head">Leaderboard Position: {ordinal_suffix_of(data?.user?.leaderboardPosition)}</Link>
                            </div>
                        </div>
                        <div className="division">
                            <div className="vol_details">
                                <div className="photo">
                                    {/* <img src={photo} alt="" /> */}
                                    {
                                        data?.user &&
                                        <span>{`${data?.user?.firstName?.charAt(0)}${data?.user?.lastName?.charAt(0)}`}</span>
                                    }
                                </div>
                                <div className="right">
                                    <div className="name_no">
                                        <div className="name">{`${data?.user?.firstName} ${data?.user?.lastName}`}</div>
                                        {/* <div className="vol">Volunteer Number: 4</div> */}
                                    </div>
                                    {/* <div className="verified">
                                        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.52536 12.3393C8.20334 12.1405 7.79666 12.1405 7.47464 12.3393L4.7838 14.0007C4.02299 14.4704 3.07521 13.7818 3.28686 12.9131L4.03543 9.84056C4.12501 9.47286 3.99934 9.08609 3.71074 8.84127L1.29914 6.79553C0.617282 6.21711 0.979302 5.10293 1.87092 5.03577L5.0244 4.79823C5.40179 4.76981 5.73079 4.53077 5.87445 4.18064L7.07484 1.2549C7.41424 0.427675 8.58576 0.427677 8.92516 1.2549L10.1256 4.18064C10.2692 4.53077 10.5982 4.76981 10.9756 4.79823L14.1291 5.03577C15.0207 5.10293 15.3827 6.21711 14.7009 6.79553L12.2893 8.84127C12.0007 9.08609 11.875 9.47286 11.9646 9.84056L12.7131 12.9131C12.9248 13.7818 11.977 14.4704 11.2162 14.0007L8.52536 12.3393Z" fill="#FDAE00" />
                                        </svg>
                                        Verified
                                    </div> */}
                                    <div className="__right __next">
                                        <div className="up">{data?.totalRegistered}</div>
                                        <div className="down">Registered</div>
                                    </div>
                                    <div className="__right ">
                                        <div className="up">Location</div>
                                        <div className="down">{`${data?.user?.state} State`}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="division">
                            <div className="row">
                                {
                                    data2?.data &&
                                    data2.data.map(data => (
                                        <ProfileCard user={`${data.firstName} ${data.lastName}`} email={`${data.email}`} link={`/people/user/${data.id}`} details={data} key={data.id} />
                                    ))
                                }
                            </div>
                        </div>
                        {
                            data2?.totalCount > (pageSize) &&
                            <div className="pagination">
                                <div className="pagination_container">
                                    <button
                                        className={`dest ${pageNumber === 1 ? 'disabled' : ''}`}
                                        disabled={pageNumber === 1}
                                        onClick={() => {
                                            navigate(`?page=${pageNumber - 1}`);
                                        }}
                                    >
                                        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.5 9L1.5 5L5.5 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                    {
                                        pagesCount.map((page, i) => (
                                            <button
                                                className={`dest ${pageNumber === (i + 1) ? 'active' : ''}`}
                                                key={i}
                                                onClick={() => {
                                                    navigate(`?page=${i + 1}`);
                                                }}
                                            >
                                                {i + 1}
                                            </button>
                                        ))
                                    }
                                    {/* <button className="dest">..</button>*/}
                                    <button
                                        className={`dest ${pageNumber === pagesCount.length ? 'disabled' : ''}`}
                                        disabled={pageNumber === pagesCount.length}
                                        onClick={() => {
                                            navigate(`?page=${pageNumber + 1}`);
                                        }}
                                    >
                                        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.5 9L5.5 5L1.5 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        }
                    </LightWrapper>
                }
            </div>
        </DashboardLayout>
    );
};

export default Volunteer;
