import Cookies from 'js-cookie';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useUpdateEffect } from 'react-use';
import { useGetDonationsQuery } from '../../api/donations';
import CheckboxOutline from '../../components/inputs/CheckboxOutline';
import DashboardLayout from '../../components/layoutComponents/DashboardLayout';
import LightWrapper from '../../components/sharedComponents/LightWrapper';
import { numberWithCommas } from '../../utilities/numbersWithCommas';
import { useDispatch, useSelector } from 'react-redux';
import { setPagesCount } from '../../app/features/donationsSlice';
import { ThreeDots } from 'react-loader-spinner';
import { useLocation, useNavigate } from "react-router-dom";
import emptyIcon from '../../images/bankrupt.png';
import './styles/Donations.scss';

const Donations = () => {
    const { search: _search } = useLocation();
    const pageNumber = parseInt(new URLSearchParams(_search).get("page"));

    const navigate = useNavigate();

    const [pageSize] = useState(20);
    const token = Cookies.get('CCMAdminToken');

    const dispatch = useDispatch();
    const pagesCount = useSelector((state) => state.donationsData.pagesCount);

    const { isLoading, error, isError, data, isSuccess } = useGetDonationsQuery({ token, pageNumber, pageSize }, { refetchOnMountOrArgChange: true });


    useUpdateEffect(() => {
        let pagesCountArray = [];

        for (let i = 0; i < (Math.ceil(data?.totalCount / pageSize)); i++) {
            pagesCountArray.push(i);

        }
        dispatch(setPagesCount(pagesCountArray));
    }, [isSuccess]);

    useUpdateEffect(() => {
        try {
            if (isError) toast.error(error.data.responseDescription, { autoClose: 3000 });
        } catch (error) {
            if (!window.navigator.online) toast.error('Your internet connection is off');
        }
    }, [isError]);

    return (
        <DashboardLayout>
            <div className="Donations">
                <h1>Donations</h1>

                <LightWrapper>
                    <div className="division">
                        <div className="query">
                            <div className="dropdown">
                                <span>Receipt Number</span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 12L12 16L16 12" stroke="#7C88B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className="search">
                                <input type="search" placeholder='Search' />
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="0.4">
                                        <circle cx="11" cy="11" r="7" stroke="#242F57" strokeWidth="1.5" />
                                        <path d="M16 16L19 19" stroke="#242F57" strokeWidth="1.5" strokeLinecap="square" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                    {
                        isLoading && !isSuccess &&
                        <div className="isLoading">
                            <ThreeDots type="ThreeDots" color="#0d6efd" height={30} width={120} />
                        </div>
                    }
                    {
                        data?.gallery?.length < 1 &&
                        <div className="d-flex justify-content-center align-items-center my-5 no-donations">
                            <img src={emptyIcon} alt="" />
                            <h2>No donation has been made yet</h2>
                        </div>
                    }
                    {
                        isSuccess && data?.gallery?.length > 0 &&
                        <div className="Table mt-3">
                            <table>
                                <thead>
                                    <tr>
                                        <th><CheckboxOutline /></th>
                                        <th>ID</th>
                                        <th>Customer</th>
                                        <th>Item</th>
                                        <th>Total</th>
                                        <th>Status</th>
                                        <th>Reference</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.gallery?.map(data => (
                                            <tr key={data.id}>
                                                <td><CheckboxOutline /></td>
                                                <td className='order_id'>{data.id}</td>
                                                <td className='customer text-capitalize'><div>{data.donor.split(' ')[0][0].toUpperCase()}{data.donor.split(' ')[1][0].toUpperCase()}</div>{data.donor}</td>
                                                <td>Donation</td>
                                                <td>₦{data.amount !== 0 ? numberWithCommas((data.amount).toString().substr(0, (data.amount).toString().length - 2)) : data.amount}.00</td>
                                                <td className='status'><span className={`${data.paymentStatus === 'Pending' ? 'pending' : data.paymentStatus === 'Success' ? 'success' : 'Failed'}`}>{data.paymentStatus}</span></td>
                                                <td>{data.reference}</td>
                                                <td>{moment(data.createdAt).format('MMM DD, YYYY')}</td>
                                                <td className='options'>
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="11" cy="6" r="1" fill="#7C88B1" />
                                                        <circle cx="11" cy="11" r="1" fill="#7C88B1" />
                                                        <circle cx="11" cy="16" r="1" fill="#7C88B1" />
                                                    </svg>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                    {
                        data?.totalCount > (pageSize) && data?.gallery?.length > 0 &&
                        <div className="pagination">
                            <div className="pagination_container">
                                <button
                                    className={`dest ${pageNumber === 1 ? 'disabled' : ''}`}
                                    disabled={pageNumber === 1}
                                    onClick={() => {
                                        navigate(`?page=${pageNumber - 1}`);
                                    }}
                                >
                                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 9L1.5 5L5.5 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                                {
                                    pagesCount.map((page, i) => (
                                        <button
                                            className={`dest ${pageNumber === (i + 1) ? 'active' : ''}`}
                                            key={i}
                                            onClick={() => {
                                                navigate(`?page=${i + 1}`);
                                            }}
                                        >
                                            {i + 1}
                                        </button>
                                    ))
                                }
                                {/* <button className="dest">..</button>*/}
                                <button
                                    className={`dest ${pageNumber === pagesCount.length ? 'disabled' : ''}`}
                                    disabled={pageNumber === pagesCount.length}
                                    onClick={() => {
                                        navigate(`?page=${pageNumber + 1}`);
                                    }}
                                >
                                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.5 9L5.5 5L1.5 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    }
                </LightWrapper>
            </div>
        </DashboardLayout>
    );
};

export default Donations;
