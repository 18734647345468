import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


const createRequest = (url, token) => ({
    url, headers: {
        'Authorization': `Bearer ${token}`,
    }
});


const postRequest = (url, token, details) => ({
    url,
    method: 'POST',
    headers: {
        'Authorization': `Bearer ${token}`,
    },
    body: details
});

export const people = createApi({
    reducerPath: 'people',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
    refetchOnReconnect: true,
    endpoints: (build) => ({
        getPeople: build.mutation({
            query: (data) => postRequest('/api/v1/admin/user/search', data.token, data.details),
        }),
        getVolunteers: build.mutation({
            query: (data) => postRequest('/api/v1/admin/user/search/volunteer', data.token, data.details),
        }),
        getUserById: build.query({
            query: (data) => createRequest(`/api/v1/admin/user/${data.id}`, data.token),
        }),
        getLeaderboardData: build.query({
            query: (token) => createRequest('/api/v1/admin/dashboard/leaderboard', token),
        }),
        getUsersRegByVolunteer: build.query({
            query: (data) => createRequest(`/api/v1/admin/User/volunteer/${data.id}?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`, data.token),
        }),
    }),
});

export const {
    useGetPeopleMutation,
    useGetUserByIdQuery,
    useGetVolunteersMutation,
    useGetLeaderboardDataQuery,
    useGetUsersRegByVolunteerQuery
} = people;