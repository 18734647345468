import { configureStore } from '@reduxjs/toolkit';
import { people } from '../api/people';
import { states } from '../api/states';
import { dashboard } from '../api/dashboard';
import { donations } from '../api/donations';
import { news } from '../api/news';
import dashboardReducer from './features/dashboardSlice';
import volunteerReducer from './features/VolunteerSlice';
import donationsReducer from './features/donationsSlice';
import newsReducer from './features/newsSlice';
import editNewsReducer from './features/editNewsSlice';


export const store = configureStore({
  reducer: {
    dashboardData: dashboardReducer,
    volunteerData: volunteerReducer,
    donationsData: donationsReducer,
    newsData: newsReducer,
    editNewsData: editNewsReducer,
    [people.reducerPath]: people.reducer,
    [states.reducerPath]: states.reducer,
    [dashboard.reducerPath]: dashboard.reducer,
    [donations.reducerPath]: donations.reducer,
    [news.reducerPath]: news.reducer,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(
        people.middleware,
        states.middleware,
        dashboard.middleware,
        news.middleware,
        donations.middleware
      )
});


