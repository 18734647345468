import React from 'react';
import { Link } from 'react-router-dom';
import { formatIntPhoneNumber } from '../../utilities/formatIntPhoneNumber';
// import photo2 from '../../images/Oval-2.png';
import { ordinal_suffix_of } from '../../utilities/ordinal_suffix_of';
import OutlineButton from '../inputs/OutlineButton';

const LeaderBoardPills = ({ color, position, totalRegistered, details }) => {
    return <Link to={`/volunteers/volunteer/${details.id}?page=1`} className="pill">
        <div className="division">
            <div className="left">
                <div className="photo">
                    {/* <img src={photo2} alt="" /> */}
                    <span>{`${details?.firstName.charAt(0) || ''}${details?.lastName.charAt(0) || ''}`}</span>
                </div>
                <div className="details">
                    <div className="name">{`${details?.firstName} ${details?.lastName}`}</div>
                    <div className="phone">{formatIntPhoneNumber(details.phoneNumber)}</div>
                    <div className="location text-capitalize">
                        {details.lga} LGA, {details.state} {details.state.toLowerCase().includes('state') || details.state.toLowerCase().includes('fct') ? '' : 'State'}
                    </div>
                </div>
            </div>
            <div className="right">
                <div className="buttons">
                    <div className="button">
                        <OutlineButton value={ordinal_suffix_of(position)} color={color} />
                    </div>
                    <div className="button">
                        <OutlineButton value={totalRegistered} />
                    </div>
                </div>
                <div className="options">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="11" cy="6" r="1" fill="#7C88B1" />
                        <circle cx="11" cy="11" r="1" fill="#7C88B1" />
                        <circle cx="11" cy="16" r="1" fill="#7C88B1" />
                    </svg>
                </div>
            </div>
        </div>
    </Link>;
};

export default LeaderBoardPills;
