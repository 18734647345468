import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import photo from '../../images/Bitmap.png';
import './styles/Header.scss';

const Header = () => {
    const [CCMAdminDetails, setCCMAdminDetails] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const search = (e) => {
        e.preventDefault();

        navigate(`/people?searchTerm=${searchTerm.trim()}&page=1`);
    };

    useEffect(() => {
        try {
            const CCMAdminDetails = JSON.parse(Cookies.get('CCMAdminDetails'));
            setCCMAdminDetails(CCMAdminDetails);
        } catch (error) {
        }
    }, []);

    const handleLogout = () => {
        Cookies.remove('CCMAdminToken');
        Cookies.remove('CCMAdminDetails');
        navigate('/login');
    };
    return (
        <header className='Header'>
            <div className="left">
                <form onSubmit={search}>
                    <div className="search">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.4">
                                <circle cx="11" cy="11" r="7" stroke="#242F57" strokeWidth="1.5" />
                                <path d="M16 16L19 19" stroke="#242F57" strokeWidth="1.5" strokeLinecap="square" />
                            </g>
                        </svg>
                        <input type="search" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} placeholder='Search..' id='search' />
                    </div>
                </form>
            </div>
            <div className="right">
                {/* <div className="notification">
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 5.83911C11 5.28683 10.7989 4.00005 12 4.00005C13.201 4.00005 13 5.28683 13 5.83911" stroke="#7C88B1" strokeWidth="1.5" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M5 12.8391C5 8.97312 8.13401 5.83911 12 5.83911V5.83911C15.866 5.83911 19 8.97312 19 12.8391V17.4842C19 17.5122 19.0101 17.5393 19.0284 17.5604L19.5686 18.1848C20.1291 18.8325 19.6689 19.8391 18.8124 19.8391H5.18761C4.33107 19.8391 3.87094 18.8325 4.43139 18.1848L4.97161 17.5604C4.98992 17.5393 5 17.5122 5 17.4842V12.8391Z" stroke="#7C88B1" strokeWidth="1.5" />
                        <circle cx="21.5" cy="2.5" r="2.5" fill="#369AFE" />
                        <path d="M10 20.5891C10 21.6937 10.8954 22.5891 12 22.5891C13.1046 22.5891 14 21.6937 14 20.5891" stroke="#7C88B1" strokeWidth="1.5" />
                    </svg>
                </div> */}
                {
                    CCMAdminDetails &&
                    <div className="profile">
                        <div className="profile_container">
                            <div className="photo">
                                {/* <img src={photo} alt="" /> */}
                                <span>{`${CCMAdminDetails?.firstName.charAt(0)}${CCMAdminDetails?.lastName.charAt(0)}`}</span>
                            </div>
                            <div className="user">{`${CCMAdminDetails?.firstName} ${CCMAdminDetails?.lastName}`}</div>
                        </div>
                        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L4.97171 4.45873L9 1" stroke="#7C88B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <div className="options">
                            <Link to="/settings">Profile</Link>
                            <span onClick={handleLogout}>Sign Out</span>
                        </div>
                    </div>
                }
            </div>
        </header>
    );
};

export default Header;
