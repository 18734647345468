import React from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from './pages/auth/Login';
import ResetPassword from './pages/auth/ResetPassword';
import Dashboard from './pages/dashboard';
import SideNav from './components/layoutComponents/SideNav';
import People from './pages/people';
import Volunteers from './pages/volunteers';
import Donations from './pages/donations';
import Events from './pages/events';
import Settings from './pages/settings';
import Header from './components/layoutComponents/Header';
import Post from './pages/spectrumReports/post';
import User from './pages/people/User';
import Volunteer from './pages/volunteers/Volunteer';
import LeaderBoard from './pages/volunteers/LeaderBoard';
import RequireAuth from './middlewares/RequireAuth';
import RouteLayout from './middlewares/RouteLayout';
import CheckAuth from './middlewares/CheckAuth';
import SpectrumReports from './pages/spectrumReports';
import CreatePost from "./pages/spectrumReports/CreatePost";
import Edit from "./pages/spectrumReports/post/Edit";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

function App() {
  const { pathname } = useLocation();
  window.addEventListener('offline', (e) => {
    toast.error('Your internet connection is down');
  });
  return (
    <div className="App">
      <div className="layout">
        {
          (pathname !== '/login' && pathname !== '/resetpassword' && pathname !== '/') &&

          <div className="side_nav">
            <SideNav />
          </div>
        }
        <ToastContainer />
        <div className="display" style={{ width: `${(pathname === '/login' || pathname === '/resetpassword' || pathname === '/') ? '100%' : 'calc(100% - 233px)'}` }}>
          {
            (pathname !== '/login' && pathname !== '/resetpassword' && pathname !== '/') &&
            <Header />
          }
          <Routes>
            <Route path="/" element={<RouteLayout />}>

              <Route element={<CheckAuth />}>

                {/* auth page routes start */}
                <Route path='/' element={<Navigate to='/login' />} />
                <Route path='/login' element={<Login />} />
                <Route path='/resetpassword' element={<ResetPassword />} />
                {/* auth page routes end */}

              </Route>


              <Route element={<RequireAuth />}>

                {/* dashboard routes start */}
                <Route path='/dashboard' element={<Dashboard />} />
                {/* dashboard routes end */}


                {/* people routes start */}
                <Route path='/people' element={<People />} />
                <Route path='/people/user/:id' element={<User />} />
                {/* people routes end */}


                {/* volunteers routes start */}
                <Route path='/volunteers' element={<Volunteers />} />
                <Route path='/volunteers/volunteer/:id' element={<Volunteer />} />
                <Route path='/volunteers/leaderboard' element={<LeaderBoard />} />
                {/* volunteers routes end */}


                {/* news and updates routes start */}
                <Route path='/spectrumreports' element={<SpectrumReports />} />
                <Route path='/spectrumreports/post/:id' element={<Post />} />
                <Route path='/spectrumreports/post/edit/:id' element={<Edit />} />
                <Route path='/spectrumreports/create' element={<CreatePost />} />
                {/* news and updates routes end */}


                {/* events routes start */}
                <Route path='/events' element={<Events />} />
                {/* events routes end */}


                {/* donations routes start */}
                <Route path='/donations' element={<Donations />} />
                {/* donations routes end */}


                {/* settings routes start */}
                <Route path='/settings' element={<Settings />} />
                {/* settings routes end */}
              </Route>

            </Route>
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
