import React from 'react';

const LeaderBoardPillsSkeleton = () => {
    return <div className="LeaderBoardPillsSkeleton">
        <div className="division">
            <div className="left">
                <div className="photo">
                </div>
                <div className="details">
                    <div className="name"></div>
                    <div className="phone"></div>
                    <div className="location"></div>
                </div>
            </div>
            <div className="right">
                <div className="buttons">
                    <div className="button">
                    </div>
                    <div className="button">
                    </div>
                </div>
                <div className="options">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="11" cy="6" r="1" fill="#7C88B1" />
                        <circle cx="11" cy="11" r="1" fill="#7C88B1" />
                        <circle cx="11" cy="16" r="1" fill="#7C88B1" />
                    </svg>
                </div>
            </div>
        </div>
        <div className="shimmer-wrapper">
            <div className="shimmer"></div>
        </div>
    </div>;
};

export default LeaderBoardPillsSkeleton;
