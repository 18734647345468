import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import markdownToTxt from 'markdown-to-txt';
import LightWrapper from './LightWrapper';
import listenForOutsideClicks from "../../utilities/listen-for-outside-clicks";

const NewsCard = ({ details }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate();

    const menuRef = useRef(null);
    const [listening, setListening] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(listenForOutsideClicks(listening, setListening, menuRef, setShowDropdown));

    return (
        <div>
            <LightWrapper>
                <div className="division">
                    <div className="head">
                        <div>
                            <div className="photo">
                                {/* <img src={details?.imageUrl} alt="" /> */}
                                <span>{`${!details.author.includes(' ') ? details?.author.charAt(0) : `${details?.author?.split(' ')[0]?.charAt(0)}${details?.author?.split(' ')[1]?.charAt(0)}`}`}</span>
                            </div>
                            <div>
                                <div className="author">{details?.author}</div>
                                <div className="time">{moment(details?.updatedAt).fromNow()}</div>
                            </div>
                        </div>
                        <div c>
                            <div className="tag">{details?.category}</div>
                            <div ref={menuRef} className="option" onClick={() => setShowDropdown(!showDropdown)}>
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11" cy="6" r="1" fill="#7C88B1" />
                                    <circle cx="11" cy="11" r="1" fill="#7C88B1" />
                                    <circle cx="11" cy="16" r="1" fill="#7C88B1" />
                                </svg>
                                {
                                    showDropdown &&
                                    <div className="dropdown">
                                        <div onClick={() => navigate(`post/edit/${details.id}`)} >
                                            <div>Edit</div>
                                        </div>
                                        {/* <div onClick={() => { }} >
                                            <div>Save as Draft</div>
                                        </div>
                                        <div onClick={() => { }} >
                                            <div>Delete</div>
                                        </div> */}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        details?.imageUrl &&
                        <Link to={`post/${details?.id}`}>
                            <div className="featured_image">
                                <img src={details?.imageUrl} alt="" />
                            </div>
                        </Link>
                    }
                    <Link to={`post/${details?.id}`}>
                        <h3 className="title">{details?.title}</h3>
                    </Link>
                    {
                        details?.body &&
                        <div className="text">
                            {markdownToTxt((details?.body)).substr(0, 230).concat("...")}
                            <Link to={`post/${details?.id}`}>
                                <span> Read more…</span>
                            </Link>
                        </div>
                    }
                </div>
                <div className="hr"></div>
                <div className="division">
                    {/* <div className="reactions">
                        <div className='react'>
                            <div className="likes">
                                <div className="count">4</div>
                                Likes
                            </div>
                            <div className="comments"> 12 Comments</div>
                            <div className="shares">1 Share</div>
                        </div>
                        <div className="options">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11" cy="6" r="1" fill="#7C88B1" />
                                <circle cx="11" cy="11" r="1" fill="#7C88B1" />
                                <circle cx="11" cy="16" r="1" fill="#7C88B1" />
                            </svg>
                        </div>
                    </div> */}
                </div>
            </LightWrapper>
        </div>
    );
};

export default NewsCard;
