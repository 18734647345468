import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    file: null,
    author: '',
    title: '',
    storylink: '',
    category: '',
    publish: false,
    editorState: '',
    imageUrl: ''
};

export const editNewsSlice = createSlice({
    name: 'editNewsData',
    initialState,
    reducers: {
        setFile: (state, action) => {
            state.file = action.payload;
        },
        setAuthor: (state, action) => {
            state.author = action.payload;
        },
        setTitle: (state, action) => {
            state.title = action.payload;
        },
        setStorylink: (state, action) => {
            state.storylink = action.payload;
        },
        setCategory: (state, action) => {
            state.category = action.payload;
        },
        setPublish: (state, action) => {
            state.publish = action.payload;
        },
        setEditorState: (state, action) => {
            state.editorState = action.payload;
        },
        setImageUrl: (state, action) => {
            state.imageUrl = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setFile, setAuthor, setTitle, setStorylink, setCategory, setPublish, setEditorState, setImageUrl } = editNewsSlice.actions;

export default editNewsSlice.reducer;