import React from 'react';
import './styles/skeleton.scss';

const CategorySkeleton = () => {
    return <div className="CategorySkeleton">
        <div className="shimmer-wrapper">
            <div className="shimmer"></div>
        </div>
    </div>;
};

export default CategorySkeleton;
