import Cookies from "js-cookie";
import React, { useState } from 'react';
import { toast } from "react-toastify";
import { useUpdateEffect } from "react-use";
import { useGetCategoriesQuery, useGetNewsQuery } from "../../api/news";
import Button from '../../components/inputs/Button';
// import OutlineButton from '../../components/inputs/OutlineButton';
import DashboardLayout from '../../components/layoutComponents/DashboardLayout';
import LightWrapper from '../../components/sharedComponents/LightWrapper';
import NewsCard from '../../components/sharedComponents/NewsCard';
import NewsCardSkeleton from "../../components/skeletons/NewsCardSkeleton";
import CategorySkeleton from "../../components/skeletons/CategorySkeleton";
import { useDispatch, useSelector } from 'react-redux';
import { setPagesCount } from '../../app/features/newsSlice';
import './styles/SpectrumReports.scss';
import { useLocation, useNavigate } from "react-router-dom";

const SpectrumReports = () => {
    const { search: _search } = useLocation();
    const pageNumber = parseInt(new URLSearchParams(_search).get("page"));
    const category = new URLSearchParams(_search).get("category") || '';

    const [pageSize] = useState(2);

    const dispatch = useDispatch();
    const pagesCount = useSelector((state) => state.newsData.pagesCount);
    const navigate = useNavigate();

    const token = Cookies.get('CCMAdminToken');

    const { isLoading, error, isError, data, isSuccess } = useGetNewsQuery({ token, category, pageNumber, pageSize }, { refetchOnMountOrArgChange: true });
    const { isLoading: isLoadingCategories, error: errorCategories, isError: isErrorCategories, data: dataCategories } = useGetCategoriesQuery(token);

    useUpdateEffect(() => {
        let pagesCountArray = [];

        for (let i = 0; i < (Math.ceil(data?.totalCount / pageSize)); i++) {
            pagesCountArray.push(i);
        }
        dispatch(setPagesCount(pagesCountArray));
    }, [isSuccess]);

    useUpdateEffect(() => {
        try {
            if (isError) toast.error(error.data.responseDescription, { autoClose: 3000 });
        } catch (error) {
            if (!window.navigator.online) toast.error('Your internet connection is off');
        }
    }, [isError]);

    useUpdateEffect(() => {
        try {
            if (isErrorCategories) toast.error(errorCategories.data.responseDescription, { autoClose: 3000 });
        } catch (error) {
            if (!window.navigator.online) toast.error('Your internet connection is off');
        }
    }, [isErrorCategories]);

    return (
        <DashboardLayout>
            <div className="SpectrumReports">
                <div className="row">
                    <div className="col-12 col-lg-8">
                        <div className="posts">
                            {
                                (!isLoading && data?.data?.length === 0) &&
                                <h3 className="text-center p-2">There is no News for this Category</h3>
                            }
                            {
                                (isLoading || !data) &&
                                [1, 2, 3].map((news, i) => (
                                    <NewsCardSkeleton key={i} />
                                ))
                            }
                            {
                                (!isLoading && data?.data) &&
                                data?.data.map(news => (
                                    <NewsCard details={news} key={news.id} />
                                ))
                            }
                            {
                                (!isLoading && data?.data && data?.totalCount > (pageSize)) &&
                                <LightWrapper padding={true}>
                                    <div className="pagination">
                                        <div className="page">Page {pageNumber} of {pagesCount.length}</div>
                                        <div>
                                            {
                                                data?.totalCount > (pageSize) &&
                                                <div className="pagination">
                                                    <div className="pagination_container">
                                                        <button
                                                            className={`dest ${pageNumber === 1 ? 'disabled' : ''}`}
                                                            disabled={pageNumber === 1}
                                                            onClick={() => {
                                                                navigate(`?page=${pageNumber - 1}`);
                                                            }}
                                                        >
                                                            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5.5 9L1.5 5L5.5 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M5.5 9L1.5 5L5.5 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </button>
                                                        {
                                                            pagesCount.map((page, i) => (
                                                                <button
                                                                    className={`dest ${pageNumber === (i + 1) ? 'active' : ''}`}
                                                                    key={i}
                                                                    onClick={() => {
                                                                        navigate(`?page=${i + 1}`);
                                                                    }}
                                                                >
                                                                    {i + 1}
                                                                </button>
                                                            ))
                                                        }
                                                        <button
                                                            className={`dest ${pageNumber === pagesCount.length ? 'disabled' : ''}`}
                                                            disabled={pageNumber === pagesCount.length}
                                                            onClick={() => {
                                                                navigate(`?page=${pageNumber + 1}`);
                                                            }}
                                                        >
                                                            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1.5 9L5.5 5L1.5 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1.5 9L5.5 5L1.5 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </LightWrapper>
                            }
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <LightWrapper padding={true}>
                            <div className='create_post_btn' onClick={() => navigate('create')}>
                                <Button value='CREATE NEW POST' />
                            </div>
                        </LightWrapper>
                        {/* <LightWrapper padding={true}>
                            <div className='groups'>
                                <div className="head">
                                    <span className='_groups'>Groups</span>
                                    <span className='see_all'>See All</span>
                                </div>
                                <div className="group_list">
                                    <div className="group">
                                        <div className="cover">D</div>
                                        Group 1
                                    </div>
                                    <div className="group">
                                        <div className="cover">F</div>
                                        Group 2
                                    </div>
                                    <div className="group">
                                        <div className="cover">B</div>
                                        Group 3
                                    </div>
                                    <div className="group">
                                        <div className="cover">S</div>
                                        Group d
                                    </div>
                                </div>
                                <div>
                                    <OutlineButton value='CREATE GROUP' />
                                </div>
                            </div>
                        </LightWrapper> */}
                        {/* <div className="px-4 mb-4">
                            <OutlineButton value='CREATE GROUP' />
                        </div> */}
                        <LightWrapper padding={true}>
                            <div className='groups'>
                                <div className="head">
                                    <span className='_groups'>Categories</span>
                                    {/* <span className='see_all'>See All</span> */}
                                </div>

                                <div className="categories">
                                    <div className={`pills ${category === '' ? 'active' : ''}`} onClick={() => navigate(`?page=1`)}>All</div>
                                    {
                                        (isLoadingCategories || !data) &&
                                        [1, 2, 3, 4, 5].map((cat, i) => (
                                            <CategorySkeleton key={i} />
                                        ))
                                    }
                                    {
                                        (!isLoadingCategories && dataCategories?.data) &&
                                        dataCategories?.data?.map(cat => (
                                            <div className={`pills ${category === cat.name ? 'active' : ''}`} onClick={() => navigate(`?category=${cat.name}&page=1`)} key={cat.id}>{cat.name}</div>
                                        ))
                                    }
                                </div>
                            </div>
                        </LightWrapper>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
};

export default SpectrumReports;
