import React from 'react';
import { Link } from 'react-router-dom';
import OutlineButton from '../inputs/OutlineButton';
import LightWrapper from './LightWrapper';
import './styles/ProfileCard.scss';

const ProfileCard = ({ photo, user, email, link, details }) => {
    return (
        <div className="col-12 col-md-6 col-lg-3">
            <LightWrapper padding={true}>
                <div className="ProfileCard">
                    <div className="options">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11" cy="6" r="1" fill="#7C88B1" />
                            <circle cx="11" cy="11" r="1" fill="#7C88B1" />
                            <circle cx="11" cy="16" r="1" fill="#7C88B1" />
                        </svg>
                    </div>
                    <div className="photo">
                        {/* <img src={photo} alt="" /> */}
                        <span>{`${details?.firstName.charAt(0) || ''}${details?.lastName.charAt(0) || ''}`}</span>
                    </div>
                    <div className="user">{user}</div>
                    <div className="email">{email}</div>
                    <Link to={link} className="__btn">
                        <OutlineButton value='See Bio' />
                    </Link>
                </div>
            </LightWrapper>
        </div>
    );
};

export default ProfileCard;
