import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUpdateEffect } from 'react-use';
import { useGetVolunteersMutation } from '../../api/people';
import { useGetStatesQuery } from '../../api/states';
import Button from '../../components/inputs/Button';
// import Input2 from '../../components/inputs/Input2';
import SelectInput from '../../components/inputs/SelectInput';
import DashboardLayout from '../../components/layoutComponents/DashboardLayout';
import ProfileCard from '../../components/sharedComponents/ProfileCard';
import ProfileCardSkeleton from '../../components/skeletons/ProfileCardSkeleton';
import photo from '../../images/Oval-2.png';
import './styles/Volunteers.scss';

const Volunteers = () => {
    const [volunteer, volunteersResult] = useGetVolunteersMutation();
    // const [pageNumber, setPageNumber] = useState(1);
    const [pageSize] = useState(20);
    const [state, setState] = useState('');
    const [religion, setReligion] = useState('');
    const [gender, setGender] = useState('');
    const [pvc, setPVC] = useState('');
    const [pagesCount, setPagesCount] = useState([]);
    const [searchQuery, setSearchQuery] = useState(['Christian', 'Male', 'Lagos']);


    const { search: _search } = useLocation();
    const pageNumber = parseInt(new URLSearchParams(_search).get("page"));

    const navigate = useNavigate();

    const getStateValue = (value) => {
        setState(value);
    };

    const getReligionValue = (value) => {
        setReligion(value);
    };

    const getGenderValue = (value) => {
        setGender(value);
    };

    const getPVCValue = (value) => {
        setPVC(value);
    };

    useEffect(() => {
        setSearchQuery([`${state}${state && ','}`, `${religion}${religion && ','}`, `${gender}${gender && ','}`, pvc]);
    }, [gender, pvc, religion, state]);

    const token = Cookies.get('CCMAdminToken');


    const { data: statesData, isLoading: isLoadingStates, isError: isErrorStates, error: errorStates } = useGetStatesQuery(token);


    const { isLoading, error, isError, data, isSuccess } = volunteersResult;

    useUpdateEffect(() => {
        let pagesCountArray = [];

        for (let i = 0; i < (Math.ceil(data?.totalCount / pageSize)); i++) {
            pagesCountArray.push(i);

        }
        setPagesCount(pagesCountArray);
    }, [isSuccess]);

    useUpdateEffect(() => {
        try {
            if (isErrorStates) toast.error(errorStates.data.responseDescription, { autoClose: 3000 });
        } catch (error) {
            if (!window.navigator.online) toast.error('Your internet connection is off');
        }
    }, [isErrorStates]);

    useUpdateEffect(() => {
        try {
            if (isError) toast.error(error.data.responseDescription, { autoClose: 3000 });
        } catch (error) {
            if (!window.navigator.online) toast.error('Your internet connection is off');
        }
    }, [isError]);

    useEffect(() => {
        let details = {
            pageNumber,
            pageSize: pageSize,
            search: "",
            state: '',
            religion: '',
            gender: '',
            hasPvc: 2,
            isVolunteer: 2
        };
        volunteer({ token, details });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, volunteer, token]);

    const search = () => {
        navigate('?page=1');
        let details = {
            pageNumber: pageNumber,
            pageSize,
            search: "",
            state: state.split(' ')[0],
            religion: religion,
            gender: gender,
            hasPvc: pvc === '' ? 2 : pvc === 'Registered' ? 1 : 0,
            isVolunteer: 2
        };
        volunteer({ token, details });
    };

    useUpdateEffect(() => {
        let details = {
            pageNumber: pageNumber,
            pageSize,
            search: "",
            state: state.split(' ')[0],
            religion: religion,
            gender: gender,
            hasPvc: pvc === '' ? 2 : pvc === 'Registered' ? 1 : 0,
            isVolunteer: 2
        };
        volunteer({ token, details });
    }, [pageNumber]);


    return (
        <DashboardLayout>
            <main className='Volunteers'>
                <div className="title">
                    <div>
                        {
                            data &&
                            <h1>{data?.totalCount} Volunteers Found</h1>
                        }
                        <div className='search_queries'>Search Query:  <span>{searchQuery.join(' ')}</span></div>
                    </div>
                    <Link to='leaderboard'>
                        <Button value='See Leaderboard' bgColor='#AAADB7' />
                    </Link>
                </div>

                <section className="search_by">
                    <div className='_select'>
                        <span>Search by</span>
                        <SelectInput option='State of Resident' options={statesData?.data?.map(state => state.name) || []} getValue={getStateValue} />
                        <SelectInput option='Religion' options={['Christianity', 'Islam', 'Others']} getValue={getReligionValue} />
                        {/* <Input2 placeholder='Religion' onChange={(e) => setReligion(e.target.value)} type='search' /> */}
                        <SelectInput option='Gender' options={['Male', 'Female', 'Other']} getValue={getGenderValue} />
                        <SelectInput option='PVC Status' options={['Registered', 'Not registered']} getValue={getPVCValue} />
                    </div>
                    <div className='_btn' onClick={search}>
                        <Button value='Search' />
                    </div>
                </section>

                <section className='profile_cards'>
                    <div className="row">
                        {
                            (isLoading || isLoadingStates || !data) &&
                            [1, 2, 3, 4, 5, 6, 7, 8].map(i => (
                                <ProfileCardSkeleton key={i} />
                            ))
                        }
                        {
                            !isLoading &&
                            data?.data?.map(data => (
                                <ProfileCard photo={photo} user={`${data.firstName} ${data.lastName}`} email={`${data.email}`} link={`volunteer/${data.id}?page=1`} details={data} key={data.id} />
                            ))
                        }
                    </div>
                </section>
                {
                    data?.totalCount > (pageSize) &&
                    <div className="pagination">
                        <div className="pagination_container">
                            <button
                                className={`dest ${pageNumber === 1 ? 'disabled' : ''}`}
                                disabled={pageNumber === 1}
                                onClick={() => {
                                    navigate(`?page=${pageNumber - 1}`);
                                }}
                            >
                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.5 9L1.5 5L5.5 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                            {
                                pagesCount.map((page, i) => (
                                    <button
                                        className={`dest ${pageNumber === (i + 1) ? 'active' : ''}`}
                                        key={i}
                                        onClick={() => {
                                            navigate(`?page=${i + 1}`);
                                        }}
                                    >
                                        {i + 1}
                                    </button>
                                ))
                            }
                            {/* <button className="dest">..</button>*/}
                            <button
                                className={`dest ${pageNumber === pagesCount.length ? 'disabled' : ''}`}
                                disabled={pageNumber === pagesCount.length}
                                onClick={() => {
                                    navigate(`?page=${pageNumber + 1}`);
                                }}
                            >
                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.5 9L5.5 5L1.5 1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                        </div>
                    </div>
                }
            </main>
        </DashboardLayout>
    );
};

export default Volunteers;
