import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


const createRequest = (url, token) => ({
    url, headers: {
        'Authorization': `Bearer ${token}`,
    }
});


const postRequest = (url, token, details) => ({
    url,
    method: 'POST',
    headers: {
        'Authorization': `Bearer ${token}`,
    },
    body: details
});


const putRequest = (url, token, details) => ({
    url,
    method: 'PUT',
    headers: {
        'Authorization': `Bearer ${token}`,
    },
    body: details
});

export const news = createApi({
    reducerPath: 'news',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
    refetchOnReconnect: true,
    tagTypes: ['news'],
    endpoints: (build) => ({
        getNews: build.query({
            query: (data) => createRequest(`/api/v1/admin/News?pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&search&status=2&category=${data.category}`, data.token),
            providesTags: ['news'],
        }),
        CreateNews: build.mutation({
            query: (data) => postRequest('/api/v1/admin/News/create', data.token, data.formData),
            invalidatesTags: ['news'],
        }),
        updateNews: build.mutation({
            query: (data) => putRequest('/api/v1/admin/News/update', data.token, data.formData),
            invalidatesTags: ['news'],
        }),
        getNewsById: build.query({
            query: (data) => createRequest(`/api/v1/admin/News/${data.id}`, data.token),
            providesTags: ['news'],
            keepUnusedDataFor: 0,
        }),
        getCategories: build.query({
            query: (token) => createRequest('/api/v1/News/category', token),
        }),
    }),
});

export const {
    useGetNewsQuery,
    useGetNewsByIdQuery,
    useGetCategoriesQuery,
    useCreateNewsMutation,
    useUpdateNewsMutation
} = news;