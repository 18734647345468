import Cookies from "js-cookie";
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import AuthApi from "../../api/AuthApi";
import Button from "../../components/inputs/Button";
import Input from '../../components/inputs/Input';
import DashboardLayout from '../../components/layoutComponents/DashboardLayout';
import LightWrapper from '../../components/sharedComponents/LightWrapper';
import { formatIntPhoneNumber } from "../../utilities/formatIntPhoneNumber";
import './styles/Settings.scss';

const Settings = () => {

    const [text, setText] = useState('');

    const CCMAdminDetails = JSON.parse(Cookies.get('CCMAdminDetails'));

    const [buttonValue, setButtonValue] = useState('Update Password');


    const { control, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        let details = {};
        const { password, newPassword, confirmNewPassword } = data;
        details.password = password;
        details.newPassword = newPassword;
        details.confirmNewPassword = confirmNewPassword;

        setButtonValue(<ThreeDots type="ThreeDots" color="#FFF" height={16} width={80} />);

        try {
            const response = await AuthApi.changePassword({ details, token: CCMAdminDetails.token });
            if (response.responseCode === "10400") {
                toast.error(response.responseDescription);
            } else {
                toast.success(response.responseDescription);
            }
        } catch (error) {
        }

        setButtonValue('Update Password');

    };

    useEffect(() => {
        if (errors.newPassword) {
            toast.error(errors.newPassword.message);
        }
        if (errors.confirmNewPassword) {
            toast.error(errors.confirmNewPassword.message);
        }
        if (errors.password) {
            toast.error(errors.password.message);
        }
    }, [errors.password, errors.newPassword, errors.confirmNewPassword]);


    return (
        <DashboardLayout>
            <div className="Settings">
                <LightWrapper padding={true}>
                    <div className="head">
                        <div>
                            <div className="change_password" style={{ fontWeight: 'bold' }}>User Profile</div>
                            <div className="text">This information can not be edited</div>
                        </div>
                    </div>

                    <form>
                        <div className="row">
                            <div className="col col-md-6">
                                <Input label='FIRST NAME' value={CCMAdminDetails.firstName || text} onChange={(e) => setText(e.target.value)} disabled={true} />
                            </div>
                            <div className="col col-md-6">
                                <Input label='LAST NAME' value={CCMAdminDetails.lastName || text} onChange={(e) => setText(e.target.value)} disabled={true} />
                            </div>
                        </div>
                        <Input label='EMAIL' type='email' value={CCMAdminDetails.email || text} onChange={(e) => setText(e.target.value)} disabled={true} />
                        <Input label='PHONE NUMBER' type='tel' value={formatIntPhoneNumber(CCMAdminDetails.phoneNumber) || text} onChange={(e) => setText(e.target.value)} disabled={true} />
                    </form>
                    <div className="my-5">
                    </div>
                </LightWrapper>
                <LightWrapper padding={true}>
                    <div className="head">
                        <div>
                            <div className="change_password">Change Password</div>
                            <div className="text">Forgot your password, find back in seconds</div>
                        </div>
                        <div className='update_password'>
                            Update Password
                        </div>
                    </div>

                    <form>
                        <Controller
                            control={control}
                            rules={{ required: "password is required." }}
                            name="password"
                            render={({
                                field: { onChange },
                            }) => (
                                <Input label='OLD PASSWORD' type='password' onChange={onChange} placeholder='Insert your old password' id='password' />
                            )}
                        />
                        <Controller
                            control={control}
                            rules={{ required: "new password is required." }}
                            name="newPassword"
                            render={({
                                field: { onChange },
                            }) => (
                                <Input label='NEW PASSWORD' onChange={onChange} placeholder='Insert your new password' type='password' />
                            )}
                        />
                        <Controller
                            control={control}
                            rules={{ required: "confirm new password is required." }}
                            name="confirmNewPassword"
                            render={({
                                field: { onChange },
                            }) => (
                                <Input label='CONFIRM NEW PASSWORD' onChange={onChange} placeholder='Insert your new password again' type='password' />
                            )}
                        />
                        <div className="button">
                            <div onClick={handleSubmit(onSubmit)}>
                                <Button value={buttonValue} />
                            </div>
                        </div>
                    </form>
                    <div className="my-5">
                    </div>
                </LightWrapper>
            </div>
        </DashboardLayout>
    );
};

export default Settings;
