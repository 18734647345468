import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pagesCount: [],
};

export const VolunteerSlice = createSlice({
    name: 'VolunteerData',
    initialState,
    reducers: {
        setPagesCount: (state, action) => {
            state.pagesCount = [...action.payload];
        },
    },
});

// Action creators are generated for each case reducer function
export const { setPagesCount } = VolunteerSlice.actions;

export default VolunteerSlice.reducer;