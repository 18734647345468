import React from 'react';

const Events = () => {
    return (
        <div>
            <h1>Events</h1>
        </div>
    );
};

export default Events;
