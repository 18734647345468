import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUpdateEffect } from 'react-use';
import { useGetUserByIdQuery } from '../../api/people';
import Input from '../../components/inputs/Input';
import DashboardLayout from '../../components/layoutComponents/DashboardLayout';
import LightWrapper from '../../components/sharedComponents/LightWrapper';
import UserSkeleton from '../../components/skeletons/UserSkeleton';
import { formatIntPhoneNumber } from '../../utilities/formatIntPhoneNumber';
// import photo from '../../images/Oval.png';
import './styles/User.scss';

const User = () => {
    const [text, setText] = useState('');
    const { id } = useParams();

    const token = Cookies.get('CCMAdminToken');

    const { isLoading, error, isError, data } = useGetUserByIdQuery({ token, id });

    useUpdateEffect(() => {
        try {
            if (isError) toast.error(error.data.responseDescription, { autoClose: 3000 });
        } catch (error) {
            if (!window.navigator.online) toast.error('Your internet connection is off');
        }
    }, [isError]);


    return (
        <DashboardLayout>
            {
                (isLoading || !data) &&
                <UserSkeleton />
            }
            {
                (data?.user && data) &&
                <main className='User'>
                    <h1>{`${data?.user.firstName} ${data?.user.lastName}`}</h1>

                    <LightWrapper padding={true}>
                        <h3>User Profile</h3>
                        <span>This information can not be edited</span>
                        <div className="photo_details">
                            <div className="photo">
                                {/* <img src={photo} alt="" /> */}
                                {
                                    data?.user &&
                                    <span>{`${data?.user?.firstName?.charAt(0)}${data?.user?.lastName?.charAt(0)}`}</span>
                                }
                            </div>
                            <div className="details">
                                <div className="name">{`${data?.user.firstName} ${data?.user.lastName}`}</div>
                                <div className="email">{data?.user.email}</div>
                            </div>
                        </div>

                        <form>
                            {/* <Input label='PROFILE PICTURE' value='dfsadaefwd.jpg' disabled={true} /> */}
                            <div className="row">
                                <div className="col col-md-6">
                                    <Input label='FIRST NAME' value={data?.user.firstName || text} onChange={(e) => setText(e.target.value)} disabled={true} />
                                </div>
                                <div className="col col-md-6">
                                    <Input label='LAST NAME' value={data?.user.lastName || text} onChange={(e) => setText(e.target.value)} disabled={true} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-md-6">
                                    <Input label='EMAIL' type='email' value={data?.user.email || text} onChange={(e) => setText(e.target.value)} disabled={true} />
                                </div>
                                <div className="col col-md-6">
                                    <Input label='PHONE NUMBER' type='tel' value={formatIntPhoneNumber(data?.user.phoneNumber) || text} onChange={(e) => setText(e.target.value)} disabled={true} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-md-6">
                                    <Input label='GENDER' value={data?.user.gender || text} onChange={(e) => setText(e.target.value)} disabled={true} />
                                </div>
                                <div className="col col-md-6">
                                    <Input label='HAS VOTERS CARD' value={data?.user.hasVotersCard ? 'Yes' : 'No' || text} onChange={(e) => setText(e.target.value)} disabled={true} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-md-6">
                                    <Input label='STATE' value={data?.user.state || text} onChange={(e) => setText(e.target.value)} disabled={true} />
                                </div>
                                <div className="col col-md-6">
                                    <Input label='LGA' value={data?.user.lga || text} onChange={(e) => setText(e.target.value)} disabled={true} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-md-6">
                                    <Input label='RELIGION' value={data?.user.religion || text} onChange={(e) => setText(e.target.value)} disabled={true} />
                                </div>
                                <div className="col col-md-6">
                                    <Input label='MARITAL STATUS' value={data?.user.maritalStatus || text} onChange={(e) => setText(e.target.value)} disabled={true} />
                                </div>
                            </div>
                        </form>
                    </LightWrapper>
                </main>
            }
        </DashboardLayout>
    );
};

export default User;
