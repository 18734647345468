import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const createRequest = (url, token) => ({
    url, headers: {
        'Authorization': `Bearer ${token}`,
    }
});


export const states = createApi({
    reducerPath: 'states',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
    refetchOnReconnect: true,
    endpoints: (build) => ({
        getStates: build.query({
            query: (token) => createRequest('/api/v1/address/state', token),
        }),
    }),
});

export const {
    useGetStatesQuery
} = states;