import React from 'react';
import './styles/OutlineButton.scss';

const OutlineButton = ({ value, disabled, color }) => {
    return (
        <div className="OutlineButton">
            <button
                disabled={disabled || false}
                className={`${disabled ? 'disabled' : ''}`}
                style={{
                    color: color || '369afe',
                    borderColor: color || '369afe'
                }}
            >
                {value}
            </button>
        </div>
    );
};

export default OutlineButton;
