import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    newRegistrationData: [],
    donationsData: [],
    totalRegistration: '',
    totalDonations: '',
};

export const dashboardSlice = createSlice({
    name: 'dashboardData',
    initialState,
    reducers: {
        setNewRegistrationData: (state, action) => {
            state.newRegistrationData = [...action.payload];
        },
        setDonationsData: (state, action) => {
            state.donationsData = [...action.payload];
        },
        setTotalDonations: (state, action) => {
            state.totalDonations = action.payload;
        },
        setTotalRegistration: (state, action) => {
            state.totalRegistration = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setNewRegistrationData, setDonationsData, setTotalDonations, setTotalRegistration } = dashboardSlice.actions;

export default dashboardSlice.reducer;