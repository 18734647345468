import React, { useEffect, useRef, useState } from 'react';
import DashboardLayout from '../../components/layoutComponents/DashboardLayout';
import LightWrapper from '../../components/sharedComponents/LightWrapper';
import { ResponsiveLine } from '@nivo/line';
import chart from '../../images/Group 25.png';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { format, addDays } from 'date-fns';
import { useUpdateEffect } from 'react-use';
import { toast } from 'react-toastify';
import { useGetDashboardChartQuery, useGetDashboardStatsQuery, useGetMostPopularStateQuery } from '../../api/dashboard';
import Cookies from 'js-cookie';
import moment from 'moment';
import { setDonationsData, setNewRegistrationData, setTotalDonations, setTotalRegistration } from '../../app/features/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { numberWithCommas } from '../../utilities/numbersWithCommas';
import DashboardSkeleton from '../../components/skeletons/DashboardSkeleton';
import './style/Dashboard.scss';
import listenForOutsideClicks from "../../utilities/listen-for-outside-clicks";

const Dashboard = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [customSelectValue, setCustomSelectValue] = useState('New Registration');

    const menuRef = useRef(null);
    const menuRef1 = useRef(null);
    const [listening, setListening] = useState(false);
    const [listening2, setListening2] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(listenForOutsideClicks(listening, setListening, menuRef, setShowDropdown));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(listenForOutsideClicks(listening2, setListening2, menuRef1, setShowDatePicker));

    const dispatch = useDispatch();
    const newRegistrationData = useSelector((state) => state.dashboardData.newRegistrationData);
    const donationsData = useSelector((state) => state.dashboardData.donationsData);
    const totalRegistration = useSelector((state) => state.dashboardData.totalRegistration);
    const totalDonations = useSelector((state) => state.dashboardData.totalDonations);


    const [state, setState] = useState([
        {
            startDate: addDays(new Date(), -29),
            endDate: addDays(new Date(), 1),
            key: 'selection'
        }
    ]);

    const token = Cookies.get('CCMAdminToken');

    const { isLoading, error, isError, data, isSuccess } = useGetDashboardChartQuery({
        token,
        startDate: moment(state[0].startDate).format('YYYY-MM-DD'),
        endDate: moment(state[0].endDate).format('YYYY-MM-DD')
    },
        {
            refetchOnMountOrArgChange: true
        });

    const { isLoading: isLoadingStats, error: errorStats, isError: isErrorStats, data: dataStats } = useGetDashboardStatsQuery(token);

    const { isLoading: isLoadingPopularState, error: errorPopularState, isError: isErrorPopularState, data: dataPopularState } = useGetMostPopularStateQuery(token);

    useUpdateEffect(() => {
        let newRegData = data?.data.map(data => ({
            x: moment(data.dataDate).format('MMM DD, YYYY'),
            y: data.registeredUsers
        }));

        let fullRegData = [{
            "id": "New Registration",
            "color": "hsl(210, 99%, 60%)",
            "data": newRegData
        }];
        dispatch(setNewRegistrationData(fullRegData));

        let totalReg = data?.data.reduce((a, b) => a + b.registeredUsers, 0);
        dispatch(setTotalRegistration(totalReg));

        let totalDonations = (data?.data.reduce((a, b) => a + b.donations, 0).toString());

        dispatch(setTotalDonations(totalDonations));


        let newDonationData = data?.data.map(data => ({
            x: moment(data.dataDate).format('MMM DD, YYYY'),
            y: data.donations !== 0 ? (data.donations).toString().substr(0, (data.donations).toString().length - 2) : data.donations
        }));


        let fullDonationData = [{
            "id": "New Donations",
            "color": "hsl(227, 41%, 24%)",
            "data": newDonationData
        }];
        dispatch(setDonationsData(fullDonationData));
    }, [isSuccess]);

    useUpdateEffect(() => {
        try {
            if (isError) toast.error(error.data.responseDescription, { autoClose: 3000 });
        } catch (error) {
            if (!window.navigator.online) toast.error('Your internet connection is off');
        }
    }, [isError]);

    useUpdateEffect(() => {
        try {
            if (isErrorStats) toast.error(errorStats.data.responseDescription, { autoClose: 3000 });
        } catch (error) {
            if (!window.navigator.online) toast.error('Your internet connection is off');
        }
    }, [isErrorStats]);

    useUpdateEffect(() => {
        try {
            if (isErrorPopularState) toast.error(errorPopularState.data.responseDescription, { autoClose: 3000 });
        } catch (error) {
            if (!window.navigator.online) toast.error('Your internet connection is off');
        }
    }, [isErrorPopularState]);


    return (
        <DashboardLayout>
            <main className='Dashboard'>
                <h1>Dashboard</h1>
                <div className='date' ref={menuRef1} >
                    <div className="cal">See your data visualization from <span onClick={() => setShowDatePicker(!showDatePicker)}>{format(state[0].startDate, "PP")} - {format(addDays(state[0].endDate, -1), "PP")}</span></div>
                    {
                        showDatePicker &&
                        <div className="date_picker">
                            <DateRangePicker
                                onChange={item => setState([item.selection])}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                maxDate={new Date()}
                                months={2}
                                ranges={state}
                                direction="horizontal"
                            />
                        </div>
                    }
                </div>
                {
                    (isLoading || isLoadingStats || isLoadingPopularState || !data) &&
                    <DashboardSkeleton />
                }
                {
                    (isSuccess && !isLoadingStats && data?.data && dataStats?.data && dataPopularState?.data) &&
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <LightWrapper padding={true}>
                                <div className="report">
                                    <div className="left">
                                        <h3>Canvassing Report</h3>
                                        <p>See your marketing fluctuative</p>
                                    </div>
                                    <div className="right">
                                        <div ref={menuRef} className="customSelect" onClick={() => setShowDropdown(!showDropdown)}>
                                            <div className='group'>
                                                <div className="color" style={{ backgroundColor: `${customSelectValue === 'New Registration' ? '#369afe' : '#242f57'}` }}></div>
                                                <div className="text">{customSelectValue}</div>
                                            </div>
                                            <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 1L4.97171 4.45873L9 1" stroke="#7C88B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            {
                                                showDropdown &&
                                                <div className="dropdown">
                                                    <div onClick={() => setCustomSelectValue('New Registration')}>
                                                        <div className="color" style={{ backgroundColor: '#369afe' }}></div>
                                                        <div className="text">New Registration</div>
                                                    </div>
                                                    <div onClick={() => setCustomSelectValue('Donations')}>
                                                        <div className="color" style={{ backgroundColor: '#242f57' }}></div>
                                                        <div className="text">Donations</div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="values">
                                    <div>
                                        <div className="title">New Registration</div>
                                        <div className="value">
                                            <div className="no">
                                                {numberWithCommas(totalRegistration)}
                                            </div>
                                            <div className="percentage new_registration">
                                                {/* +21.01%
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3 12.5468L6.50663 8.93842L9.20224 11.4289L14.4931 5.5" stroke="#04B78A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M14.4932 5.5H11.4932" stroke="#04B78A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M14.4932 5.5V8.5" stroke="#04B78A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="title">Donations</div>
                                        <div className="value">
                                            <div className="no">
                                                ₦{numberWithCommas(totalDonations !== '0' ? totalDonations.toString().substr(0, totalDonations.toString().length - 2).concat('.00') : totalDonations.toString().concat('.00'))}
                                            </div>
                                            <div className="percentage donations">
                                                {/* +21.01%
                                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15 5.45325L11.4934 9.06158L8.79776 6.57107L3.50693 12.5" stroke="#FA5087" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M3.50684 12.5H6.50684" stroke="#FA5087" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M3.50684 12.5V9.5" stroke="#FA5087" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <section className="canvassing_report_chart">
                                    <ResponsiveLine
                                        data={customSelectValue === 'New Registration' ? newRegistrationData : donationsData}
                                        margin={{ top: 30, right: 15, bottom: 90, left: 55 }}
                                        xScale={{ type: 'point' }}
                                        yScale={{
                                            type: 'linear',
                                            min: 'auto',
                                            max: 'auto',
                                            stacked: true,
                                            reverse: false
                                        }}
                                        yFormat=" >-.2f"
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            orient: 'middle',
                                            tickSize: 8,
                                            tickPadding: 8,
                                            tickRotation: 90,
                                            legend: '',
                                            legendOffset: 40,
                                            legendPosition: 'middle'
                                        }}
                                        axisLeft={{
                                            orient: 'left',
                                            tickSize: 8,
                                            tickPadding: 8,
                                            tickRotation: 0,
                                            // legend: 'No. of People Registered',
                                            legendOffset: -40,
                                            legendPosition: 'middle'
                                        }}
                                        enableGridX={false}
                                        enablePoints={false}
                                        colors={{ datum: 'color' }}
                                        pointColor={{ theme: 'background' }}
                                        pointBorderWidth={2}
                                        pointBorderColor={{ from: 'serieColor' }}
                                        pointLabelYOffset={-12}
                                        enableArea={true}
                                        areaOpacity={0.06}
                                        useMesh={true}
                                    />
                                </section>
                            </LightWrapper>
                        </div>
                        <div className="col-12 col-lg-4">
                            <LightWrapper>
                                <div className="division">
                                    <div className="header">
                                        <h3>Volunteer Workrate</h3>
                                        <div className="chart">
                                            <div className="track"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='hr' />
                                <div className="division">
                                    <div className="_values">
                                        <div>
                                            <div className="title">New Registration</div>
                                            <div className="value">{dataStats && numberWithCommas(dataStats?.data?.thisMonthRegistration)}</div>
                                        </div>
                                        <div>
                                            <div className="title">Last Month</div>
                                            <div className="value">{dataStats && numberWithCommas(dataStats?.data?.lastMonthRegistration)}</div>
                                        </div>
                                    </div>
                                </div>
                            </LightWrapper>
                            <LightWrapper>
                                <div className="division">
                                    <div className="header">
                                        <h3>Most Popular State</h3>
                                    </div>
                                </div>
                                <img src={chart} alt="" style={{ width: '100%', height: '106px' }} />
                                <div className="division">
                                    <div className="_values">
                                        <div>
                                            <div className="title">State</div>
                                            <div className="value">{dataPopularState?.data[0]?.state}</div>
                                        </div>
                                        <div>
                                            <div className="title">People</div>
                                            <div className="value">{numberWithCommas(dataPopularState?.data[0]?.totalRegistered)}</div>
                                        </div>
                                    </div>
                                </div>
                            </LightWrapper>
                        </div>
                    </div>
                }
            </main>
        </DashboardLayout>
    );
};

export default Dashboard;
