import Cookies from 'js-cookie';
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { toast } from 'react-toastify';

const RequireAuth = () => {
  const location = useLocation();

  try {
    const CCMAdminToken = Cookies.get('CCMAdminToken');
    return (
      CCMAdminToken
        ? <Outlet />
        : (toast.error('Please login'), <Navigate to="/login" state={{ from: location }} replace />)
    );
  } catch (error) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
};

export default RequireAuth;
