import React from 'react';
import LightWrapper from '../sharedComponents/LightWrapper';
import './styles/skeleton.scss';

const DashboardSkeleton = () => {
    return <div className="DashboardSkeleton">
        <div className="row">
            <div className="col-12 col-lg-8">
                <LightWrapper padding={true}>
                    <div className="report">
                        <div className="left">
                            <div className='h1'></div>
                            <p></p>
                        </div>
                        <div className="right">
                        </div>
                    </div>
                    <div className="values">
                        <div>
                            <div className="title"></div>
                        </div>
                        <div>
                            <div className="title"></div>
                        </div>
                    </div>

                    <section className="canvassing_report_chart">

                    </section>
                </LightWrapper>
            </div>
            <div className="col-12 col-lg-4">
                <LightWrapper>
                    <div className="division">
                        <div className="header">
                            <div></div>
                            <div className="chart">
                                <div className="track"></div>
                            </div>
                        </div>
                    </div>
                    <div className='hr' />
                    <div className="division">
                        <div className="_values">
                            <div>
                                <div className="title"></div>
                                <div className="value"></div>
                            </div>
                            <div>
                                <div className="title"></div>
                                <div className="value"></div>
                            </div>
                        </div>
                    </div>
                </LightWrapper>
                <LightWrapper>
                    <div className="division">
                        <div className="header">
                            <div className='h1'></div>
                        </div>
                    </div>
                    <div className="division">
                        <div className="_values">
                            <div>
                                <div className="title"></div>
                                <div className="value"></div>
                            </div>
                            <div>
                                <div className="title"></div>
                                <div className="value"></div>
                            </div>
                        </div>
                    </div>
                </LightWrapper>
            </div>
        </div>
        <div className="shimmer-wrapper">
            <div className="shimmer"></div>
        </div>
    </div>;
};

export default DashboardSkeleton;
