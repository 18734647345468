import Cookies from 'js-cookie';
import { useLocation, Navigate, Outlet } from "react-router-dom";

const CheckAuth = () => {
  const location = useLocation();

  try {
    const CCMAdminToken = Cookies.get('CCMAdminToken');
    const from = location.state?.from?.pathname || "/dashboard";
    return (
      CCMAdminToken
        ? <Navigate to={from} />
        : <Outlet />
    );
  } catch (error) {
    return <Outlet />;
  }
};

export default CheckAuth;
