import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pagesCount: [],
};

export const newsSlice = createSlice({
    name: 'newsData',
    initialState,
    reducers: {
        setPagesCount: (state, action) => {
            state.pagesCount = [...action.payload];
        },
    },
});

// Action creators are generated for each case reducer function
export const { setPagesCount } = newsSlice.actions;

export default newsSlice.reducer;