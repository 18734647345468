import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pagesCount: [],
};

export const donationsSlice = createSlice({
    name: 'donationsData',
    initialState,
    reducers: {
        setPagesCount: (state, action) => {
            state.pagesCount = [...action.payload];
        },
    },
});

// Action creators are generated for each case reducer function
export const { setPagesCount } = donationsSlice.actions;

export default donationsSlice.reducer;