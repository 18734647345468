import React from 'react';
import LightWrapper from '../sharedComponents/LightWrapper';
import ProfileCardSkeleton from './ProfileCardSkeleton';
import './styles/skeleton.scss';

const VolunteerSkeleton = () => {
    return <div className="VolunteerSkeleton">
        <LightWrapper>
            <div className="gray">
                <div className="division">
                    <div className="leadership_head"></div>
                </div>
            </div>
            <div className="division">
                <div className="vol_details">
                    <div className="photo">

                    </div>
                    <div className="right">
                        <div className="name_no">
                            <div className="name"></div>
                            <div className="vol"></div>
                        </div>
                        <div className="verified">
                            <div></div>
                        </div>
                        <div className="__right __next">
                            <div className="up"></div>
                            <div className="down"></div>
                        </div>
                        <div className="__right ">
                            <div className="up"></div>
                            <div className="down"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="division">
                <div className="row">
                    <ProfileCardSkeleton />
                    <ProfileCardSkeleton />
                    <ProfileCardSkeleton />
                    <ProfileCardSkeleton />
                    <ProfileCardSkeleton />
                    <ProfileCardSkeleton />
                    <ProfileCardSkeleton />
                    <ProfileCardSkeleton />
                </div>
            </div>
        </LightWrapper>
        <div className="shimmer-wrapper">
            <div className="shimmer"></div>
        </div>
    </div>;
};

export default VolunteerSkeleton;
