import Cookies from "js-cookie";
import moment from "moment";
import React from 'react';
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useUpdateEffect } from "react-use";
import { useGetNewsByIdQuery } from "../../../api/news";
import ReactMarkdown from 'react-markdown';
// import Button from '../../../components/inputs/Button';
import DashboardLayout from '../../../components/layoutComponents/DashboardLayout';
import LightWrapper from '../../../components/sharedComponents/LightWrapper';
import './styles/Post.scss';

const Post = () => {

    const { id } = useParams();
    const token = Cookies.get('CCMAdminToken');

    const { isLoading, error, isError, data } = useGetNewsByIdQuery({ token, id });

    useUpdateEffect(() => {
        try {
            if (isError) toast.error(error.data.responseDescription, { autoClose: 3000 });
        } catch (error) {
            if (!window.navigator.online) toast.error('Your internet connection is off');
        }
    }, [isError]);

    return (
        <DashboardLayout>
            <div className='Post'>
                {
                    (!isLoading && data?.data) &&
                    <LightWrapper>
                        <div className="division">
                            <div className="head">
                                <div>
                                    <div className="photo">
                                        {/* <img src={author} alt="" /> */}
                                        <span>{`${!data?.data.author.includes(' ') ? data?.data?.author.charAt(0) : `${data?.data?.author?.split(' ')[0]?.charAt(0)}${data?.data?.author?.split(' ')[1]?.charAt(0)}`}`}</span>
                                    </div>
                                    <div>
                                        <div className="author">{data?.data.author}</div>
                                        <div className="time">{moment(data?.data?.updatedAt).fromNow()}</div>
                                    </div>
                                </div>
                                {/* <div className="edit_delete">
                                    <div>
                                        <Button value='EDIT POST' />
                                    </div>
                                    <div>
                                        <Button value='DELETE POST' bgColor='#EF2F6E' />
                                    </div>
                                </div> */}
                                <div className="tag">{data?.data?.category}</div>
                            </div>
                            <div className="featured_image">
                                <img src={data?.data?.imageUrl} alt="" />
                            </div>
                            <h3 className="title">{data?.data.title}</h3>
                            <div className="mt-3">
                                <ReactMarkdown>
                                    {data?.data.body}
                                </ReactMarkdown>
                            </div>
                        </div>
                        <div className="hr"></div>
                        <div className="division">
                            {/* <div className="reactions">
                                <div className='react'>
                                    <div className="likes">
                                        <div className="count">4</div>
                                        Likes
                                    </div>
                                    <div className="comments"> 12 Comments</div>
                                    <div className="shares">1 Share</div>
                                </div>
                                <div className="options">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="11" cy="6" r="1" fill="#7C88B1" />
                                        <circle cx="11" cy="11" r="1" fill="#7C88B1" />
                                        <circle cx="11" cy="16" r="1" fill="#7C88B1" />
                                    </svg>
                                </div>
                            </div> */}
                        </div>
                    </LightWrapper>
                }
            </div>
        </DashboardLayout>
    );
};

export default Post;
