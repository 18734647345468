import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/inputs/Button';
import Checkbox from '../../components/inputs/Checkbox';
import Input from '../../components/inputs/Input';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { validateEmail } from '../../utilities/validateEmail';
import { ThreeDots } from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import AuthApi from '../../api/AuthApi';
import Cookies from 'js-cookie';
import './styles/Login.scss';

const Login = () => {
    const [buttonValue, setButtonValue] = useState('SIGN IN');
    const [rememberMe, setRememberMe] = useState(false);
    const location = useLocation();


    const from = location.state?.from?.pathname || "/dashboard";

    const navigate = useNavigate();

    const { control, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        let details = {};
        const { password, email } = data;
        const isEmail = validateEmail(email);
        if (isEmail) {
            details.userName = email;
            details.password = password;
        } else {
            toast.error('Please use a valid email');
            return;
        }

        setButtonValue(<ThreeDots type="ThreeDots" color="#FFF" height={16} width={80} />);

        try {
            const response = await AuthApi.login(details);
            console.log(response);
            if (response.responseCode === "10401") {
                toast.error(response.responseDescription);
            } else {
                if (rememberMe) {
                    Cookies.set('CCMAdminToken', response.token, { expires: 30 });
                    Cookies.set('CCMAdminDetails', JSON.stringify(response), { expires: 30 });
                } else {
                    Cookies.set('CCMAdminToken', response.token);
                    Cookies.set('CCMAdminDetails', JSON.stringify(response));
                }
                navigate(from);
            }
        } catch (error) {
        }

        setButtonValue('SIGN IN');

    };

    useEffect(() => {
        if (errors.email) {
            toast.error(errors.email.message);
        }
        if (errors.password) {
            toast.error(errors.password.message);
        }
    }, [errors.password, errors.email]);

    return (
        <div className='Login'>
            <main>
                <h1>Welcome Back!</h1>
                <form>
                    <Controller
                        control={control}
                        rules={{ required: "email is required." }}
                        name="email"
                        render={({
                            field: { onChange },
                        }) => (
                            <Input label='EMAIL' onChange={onChange} placeholder='mumuarif@gmail.com' type='email' id='email' />
                        )}
                    />
                    <Controller
                        control={control}
                        rules={{ required: "password is required." }}
                        name="password"
                        render={({
                            field: { onChange },
                        }) => (
                            <Input label='PASSWORD' placeholder='Enter password' onChange={onChange} type='password' id='password' />
                        )}
                    />
                    <Checkbox text='Remember me' checked={rememberMe} onChange={() => setRememberMe((prev) => !prev)} />
                    <div onClick={handleSubmit(onSubmit)}>
                        <Button value={buttonValue} />
                    </div>
                    <Link to='/resetpassword' className='reset_password'>Forgot Password?</Link>
                </form>
            </main>
        </div >
    );
};

export default Login;
